<template>
  <div class="checkOrdercontainer">
    <Top></Top>
    <Fix></Fix>
     <div class="check-box-top">
         <div class="check-box-top-div">
           <img :src="require('/static/images/net.png')" alt="">
           <span>订单支付</span>
         </div>
     </div>
     <div class="check-box-big">
       <div class="check-box-big-address">
          <div class="address-div1">
            <div>邮寄地址：</div>
            <div>
                <select v-model="chose" @change="pick">
                  <!-- <option selected="true">请选择邮寄地址</option> -->
                  <option v-for="(v,k) in dataArr" :value="v.addrCode" :key="k">{{v.addrProvince}}{{v.addrCity}}{{v.addrArea}}{{v.addrDetail}}</option>
                </select>
                <span class="address-div2" @click="addaddress">+邮寄地址</span>
            </div>
            <div>
              <span>{{name}}</span>
              <span>{{telephone}}</span>
            </div>
          </div>
       </div>
       <div class="order-infor">订单信息:</div>
       <div class="check-box-big-order">
         <div class="check-box-big-order-top">
           <div>订单编号</div>
           <div>商品名称</div>
           <div>服务类型</div>
           <div>类别</div>
           <div>价格</div>
         </div>
         <div class="check-box-big-order-list" :key="k" v-for="(v,k) in paylist">
           <div>{{v.orderCode}}</div>
           <div>{{v.serviceTypeName}}</div>
           <div>{{v.goodsName}}</div>
           <div v-if="v.oneCate">第{{v.oneCate}}类 {{v.oneCateName}}</div>
           <div v-else>无</div>
           <div><span>￥{{v.totalFee}}</span><span @click="setCop(v.orderPrice,v.agreementPrice,v.smallwareAgreementPrice,v.orderCode,v.couponMoney)" v-show="des == '2'&&agentType!='3'" >优惠设置</span></div>
         </div>
       </div>
       <div class="check-box-big-invoice">
         <div class="invoice-is">是否开发票：</div>
         <div class="invoice-radio">
           <div>
             <input class="radio1" value="1" v-model="get" name="type" type="radio"><span>否</span>
             <input class="radio2" value="2" v-model="get" name="type" type="radio"><span>是</span>
           </div>
           <div @click="widnopen" v-show="voiceshow">选择发票</div>
         </div>
       </div>
       <div class="check-chose" v-show="toushow">
         <span>发票抬头:</span>
         <span>{{picktou}}</span>
       </div>
       <div class="check-chose2" v-show="toushow">提示：每月25日后申请的发票，公司统一下个月初开出</div>
       <!-- <div class="check-coupon">
         <div>优惠券：</div>
         <div @click="pickConpon">选择优惠券</div>
       </div>
       <div class="check-coupon-price">
         <span>抵扣金额：</span><span>￥{{conponPrice}}.00</span>
       </div> -->
       <div class="check-box-big-pay">
         <div class="check-box-big-pay-type">支付方式：</div>
         <div :class="active">
                <div class="div1" @click="makeActive('company')">
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <img :src="require('/static/images/lv.png')" alt="">
                    </div>
                </div>
                <div class="div2" @click="makeActive('bao')">
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <img :src="require('/static/images/bao.png')" alt="">
                    </div>
                </div>
                <div class="div3" @click="makeActive('wei')">
                    <div>
                        <div></div>
                    </div>
                    <div>
                        <img :src="require('/static/images/wei.png')" alt="">
                    </div>
                </div>
            </div>
       </div>
       <div class="test-div" v-show="testshow">
          <span>转账公司名称</span>
          <input type="text" v-model="countcompany" :disabled="disabled">
       </div>
       <div class="check-contract">
         <span>委托代理合同</span>
         <span @click="setFile">生成</span>
       </div>
       <div class="check-box-big-price">
         <div>支付金额:</div>
         <div>
           <span>￥</span><span id="money">{{this.paytotal}}</span>
         </div>
       </div>
       <div class="check-box-big-price another" v-show="des == '2'&&agentType!='3'">
         <div>预估收益:</div>
         <div>
           <span>￥</span><span id="money">{{this.earning | numberFix2}}</span>
         </div>
       </div>
       <!-- <div class="check-box-big-price another">
         <div>实付金额:</div>
         <div>
           <span>￥</span><span :="money" id="money">{{this.paytotal}}</span>
         </div>
       </div> -->
       <div class="check-box-big-commit">
         <!-- <div @click="otherPay">找人代付</div> -->
         <div @click="Tonext">确认支付</div>
       </div>
     </div>
     <div class="add-address" v-show="addressshow">
       <div class="address-form">
         <img :src="require('/static/images/cha.png')" alt="" @click="cha">
         <div class="form-div">
           <div class="recevie-people">
             <span>邮寄人</span>
             <input type="text" v-model="people" maxlength="12">
           </div>
           <div>
             <span>联系电话</span>
             <input class="deal" type="text" v-model="moblie" maxlength="14">
           </div>
           <div class="address-div">
             <span>所在区</span>
             <el-select v-model="sheng" @change="choseProvince" placeholder="选择省" class="select1">
                    <el-option
                    v-for="item in province"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="shi" @change="choseCity" placeholder="选择市" class="select1">
                    <el-option
                    v-for="item in shi1"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="qu" @change="choseBlock" placeholder="选择区" class="select1">
                    <el-option
                    v-for="item in qu1"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                    </el-option>
                </el-select>
           </div>
           <div>
             <span>详细地址</span>
             <input type="text" v-model="detailaddress" maxlength="30">
           </div>
           <div class="count" @click="count">保存</div>
         </div>
       </div>
     </div>
     <div class="check-box-tan" v-show="tan">
      <div class="check-box-tan-div">
        <span>申请发票</span>
        <img @click="cha2" :src="require('/static/images/cha.png')" alt="">
        <div class="check-box-tan-type">
          <span class="check-box-tan-type-span1">选择开票类型：</span>
          <input v-model="kai" value="one" name="piao"  type="radio"><span class="check-box-tan-type-span2">普通发票（电子发票）</span>
          <input v-model="kai" value="two" name="piao" type="radio"><span>专用发票（纸质发票）</span>
        </div>
        <div class="check-box-tan-add">
          <span>已有开票信息：</span>
          <select v-model="pickcompany">
            <option value="0">请选择</option>
            <option v-for="(v,k) in arr2" :key="k" v-bind:value="v.invoiceCode">{{v.invoiceName}}</option>
          </select>
          <div @click="addInvoice">新增</div>
        </div>
        <div @click="comfire"  class="check-box-tan-comfire">确定</div>
      </div>
    </div>
    <!-- 生成合同弹窗 -->
    <div class="cont-box-tan" v-show="fileShow">
      <div class="cont-box-tan-div">
          <span>生成委托代理合同</span>
          <img @click="cha4" :src="require('/static/images/cha.png')" alt="">
          <div class="cont-box-tan-infor">
            <span>您可修改委托人和联系电话</span>
            <div>
              <span>委托人</span>
              <el-select v-model="authCode" class="cont-box-tan-div-select" @change="choseCompany">
                <el-option
                  v-for="item in dataList"
                  :key="item.authCode"
                  :label="item.comName"
                  :value="item.authCode">
                </el-option>
              </el-select>
            </div>
            <div>
              <span>联系人</span>
              <input type="text" placeholder="请输入联系人" v-model="userName" maxlength="12">
            </div>
            <div>
              <span>电话号码</span>
              <input type="text" placeholder="请输入电话号码" v-model="userPhone">
            </div>
            <div>
              <span>受托人</span>
              <input type="text" placeholder="请输入受托人" v-model="partyContactsInfo" maxlength="12">
            </div>
            <div>
              <span>电话号码</span>
              <input type="text" placeholder="请输入电话号码" v-model="partyMobilePhone">
            </div>
            <div class="cont-box-tan-sub" @click="submitFile">提交</div>
          </div>
      </div>
    </div>
    <!-- 设置优惠价格 -->
    <div class="check-edit-discont" v-show="setPriceshow">
      <div class="check-edit-div">
          <span>设置价格</span>
          <img @click="cha3" :src="require('/static/images/cha.png')" alt="">
          <div class="check-edit-set">
            <div>
              <span>服务价格</span>
              <span>￥{{serPrice}}.00</span>
            </div>
            <div>
              <span>优惠价格</span>
              <input type="text" placeholder="输入优惠金额" v-model.trim="coupon_price" onkeyup="value=value.replace(/[^\d]/g,'')">
              <div>优惠后金额不能低于{{agreementPrice}}，需为整数</div>
            </div>
            <div>
              <span>支付价格</span>
              <span>￥{{tanPay}}.00</span>
            </div>
            <div class="cont-box-tan-sub" @click="subniP">提交</div>
          </div>
      </div>
    </div>
    <!-- 找人代付 -->
    <div class="check-otherPay" v-show="otherPayshow">
      <div class="otherPay-div">
          <div class="otherPay-div1">找人代付</div>
          <img @click="cha5" :src="require('/static/images/cha.png')" alt="">
          <div class="otherPay-div2">
            <span class="div-url">https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx806766edadf0ee16&redirect_uri={{ this.GLOBAL.h5_url }}/orderPay?response_type=code&scope=snsapi_base&state={{this.$route.query.order}}&connect_redirect=1#wechat_redirect</span>
              <span class="tag-read"
                    :data-clipboard-text="'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx806766edadf0ee16&redirect_uri='+this.GLOBAL.h5_url+'/orderPay?response_type=code&scope=snsapi_base&state='+this.$route.query.order+'&connect_redirect=1#wechat_redirect'" @click="copy">复制链接</span>
          </div>
      </div>
    </div>
     <div class="weixin" v-show="weishow">
        <img @click="weicha" :src="require('/static/images/cha.png')" alt="">
        <div class="qrcode" ref="qrCodeUrl"></div>
      </div>
      <!-- 选择优惠券 -->
      <!-- <div class="check-pickCoupon" v-show="conponShow">
          <div class="check-pickCoupon-tan">
            <img :src="require('/static/images/orderdetailcha.png')" alt="" @click="closeConpon">
            <span>选择优惠券</span>
            <div class="check-pickCoupon-tan-bigDiv">
                <div class="pickCoupon-canUse">
                  <div class="pickCoupon-canUse-title" v-show="conpon1.length!=0">可使用优惠券</div>
                  <div class="pickCoupon-canUse-list">
                     <div v-for="(v,k) in conpon1" class="pickCoupon-canUse-list-detail">
                        <div class="pickCoupon-canUse-div1">
                            <div>
                              <span>￥</span>{{v.discount_amount}}
                            </div>
                            <div>满{{v.satisfy_amount}}元可用</div>
                        </div>
                        <div class="pickCoupon-canUse-div2">
                          <div>{{v.couponTitle}}</div>
                          <div>{{v.validDateBegin}}~{{v.validDateEnd}}</div>
                        </div>
                        <div class="pickCoupon-canUse-div3">
                          <el-radio v-model="radio" @change="choseConpon" :label="v.userCouponCode">{{""}}</el-radio>
                        </div>
                     </div>
                  </div>
                </div>
                <div class="pickCoupon-canUse">
                  <div class="pickCoupon-canUse-title" v-show="conpon2.length!=0">不可使用优惠券</div>
                  <div class="pickCoupon-canUse-list">
                     <div v-for="(v,k) in conpon2" class="pickCoupon-canUse-list-detail noFordid">
                        <div class="pickCoupon-canUse-div1 noUse">
                            <div>
                              <span>￥</span>{{v.discount_amount}}
                            </div>
                            <div>满{{v.satisfy_amount}}元可用</div>
                        </div>
                        <div class="pickCoupon-canUse-div2">
                          <div>{{v.couponTitle}}</div>
                          <div>{{v.validDateBegin}}~{{v.validDateEnd}}</div>
                        </div>
                        <div class="pickCoupon-noUse-div3" v-for="item in v.limitResults" v-show="item.usable == false">
                          不可使用原因：{{item.unusableReason}}
                        </div>
                     </div>
                  </div>
                </div>
            </div>
            <div class="check-pickCoupon-comfire" @click="pickCouponcomfire">确定</div>
          </div>
      </div> -->
     <Foot></Foot>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
import QRCode from 'qrcodejs2';
import Clipboard from 'clipboard';
import mapJson from '../../../static/json/map.json'
export default {
  name: 'checkOrder',
  data(){
    return{
      des:this.$cookie.getCookie('type'),
      // 三级联动
       mapJson,
        province:'',
        sheng: '',
        shi: '',
        shi1: [],
        qu: '',
        qu1: [],
        city:'',
        block:'',
        a:'',
        b:'',
        c:'',
      active:'company',
      paylist:[],
      type:2,
      // 总计价格
      // total:'',
      // 实付价格
      paytotal:'',
      // 优惠卷价格
      coupon_price:0,
      // 服务价格
      serPrice:'',
      tanPay:'',
      // 协议价格
      agreementPrice:'',
      // 优惠劵
      conpon:[],
      radio:"",
      conpon1:[],
      conpon2:[],
      // 抵扣金额
      conponPrice:0,
      get:'1',
      conponShow:false,
      testshow:true,
      addressshow:false,
      voiceshow:false,
      people:'',
      moblie:'',
      detailaddress:'',
      dataArr:[],
      name:'',
      telephone:'',
      chose:'',
      tan:false,
      kai:"one",
      pickcompany:'0',
      arr2:[],
      companyarr:[],
      totalAddress:'',
      countcompany:'',
      toushow:false,
      picktou:'',
      // 微信二维码
      weishow:false,
      // 二维码链接
      weiUrl:'',
      pay_order_no:'',
      xingimg:'',
      timer: null,
      buyType:[{id:'1',name:'AI商标注册'},{id:'2',name:'顾问商标注册'},{id:'3',name:'商标变更'},{id:'4',name:'商标续展'},{id:'5',name:'商标宽展'},{id:'6',name:'商标转让'},{id:'7',name:'商标许可备案'},{id:'8',name:'补发商标证书'}
        ,{id:'9',name:'商标异议申请'},{id:'10',name:'商标异议答辩'},{id:'11',name:'商标撤三答辩'},{id:'12',name:'商标撤三申请'},{id:'13',name:'商标驳回复审'}],

      // 预估收益
      earning:0,
     orderCode:'',
      authCode:'',
      dataList:[],
      setPriceshow:false,
      fileShow:false,
      enterpriseName:'',
      partyContactsInfo:'',
      partyMobilePhone:'',
      userPhone:'',
      userName:'',
      comRegisterAddr:'',
      otherPayshow:false,
      // 顾问信息
      consultant:{},
      disabled:false,
      // 是否是城市代理人
      agentType:''
    }
  },
  components: {
      Top,
      Foot,
      Fix
  },
  watch:{
      "get"(newValue){
          if(newValue =='1'){
              if(this.toushow == true){
                this.cancelInvoice()
              }
              this.toushow = false;
              this.countcompany = '';
              this.voiceshow=false;
              this.disabled = false;
          }else if(newValue=='2'){
            this.voiceshow=true;
          }
      },
      "kai"(newValue){
          if(newValue =='one'){
              this.pickcompany = "0";
              this.getlist();
          }else if(newValue=='two'){
            this.pickcompany = "0";
            this.getlist();
          }
      }
  },
  filters:{
    numberFix2(number){
      if(isNaN(Number(number))) return number
      return number.toFixed(2)
    }
  },
  computed:{
    money(){

    }
  },
  methods:{
    tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:2000
          })
      },
      copy() {
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
      this.tip('success','复制成功')
      this.otherPayshow = false
      // 释放内存
      clipboard.destroy()
      })
      clipboard.on('error', e => {
      // 不支持复制
      this.tip('error','该浏览器不支持自动复制')
      // 释放内存
      clipboard.destroy()
      })
      },
      // 获取认证信息
      getTest(){
        this.$http.post(this.GLOBAL.new_url+"/user/service/getAuthInfos",{
            userCode:this.$cookie.getCookie('u_id'),
            authType:"2",
            pageSize:1000,
            pageNum:1,
          },{
                        headers:{
                            AuthToken:this.$cookie.getCookie('token'),
                            'X-Request-Origin':'xcx'
                        }
                                    }).then(res=>{
            // console.log(res)
            if(res.data.code == "200"){
              this.dataList = res.data.data.list;
            }
          })
      },
    getCityData:function(){
        var that = this
            var data = this.mapJson
            that.province = []
            that.city = []
            that.block = []
            // 省市区数据分类
            for (var item in data) {
              if (item.match(/0000$/)) {//省
                that.province.push({id: item, value: data[item], children: []})
              } else if (item.match(/00$/)) {//市
                that.city.push({id: item, value: data[item], children: []})
              } else {//区
                that.block.push({id: item, value: data[item]})
              }
            }
            // 分类市级
            for (var index in that.province) {
              for (var index1 in that.city) {
                if (that.province[index].id.slice(0, 2) === that.city[index1].id.slice(0, 2)) {
                  that.province[index].children.push(that.city[index1])
                }
              }
            }
            // 分类区级
            for(var item1 in that.city) {
              for(var item2 in that.block) {
                if (that.block[item2].id.slice(0, 4) === that.city[item1].id.slice(0, 4)) {
                  that.city[item1].children.push(that.block[item2])
                }
              }
            }


      },
      // 选省
      choseProvince:function(e) {
        for (var index2 in this.province) {
          if (e === this.province[index2].id) {
            this.shi1 = this.province[index2].children
            this.shi = this.province[index2].children[0].value
            this.qu1 =this.province[index2].children[0].children
            this.qu = this.province[index2].children[0].children[0].value
            this.c = this.province[index2].children[0].children[0].value
            this.E = this.qu1[0].id
            // console.log(this.province[index2].value);
           this.a =  this.province[index2].value
          }
        }
      },
      // 选市
      choseCity:function(e) {
        for (var index3 in this.city) {
          if (e === this.city[index3].id) {
            this.qu1 = this.city[index3].children
            this.qu = this.city[index3].children[0].value
            this.E = this.qu1[0].id
            // console.log(this.city[index3].value);
            this.b = this.city[index3].value
          }
        }
      },
      // 选区
      choseBlock:function(e) {
        this.E=e;
        for(var index4 in this.qu1){
          if(e === this.qu1[index4].id){
            // console.log(this.qu1[index4].value)
            this.c =this.qu1[index4].value
          }
        }
      },
      addaddress(){
        this.addressshow = true;
      },
      cha(){
        this.addressshow = false;
      },
      cha2(){
        this.tan= false;
      },
      cha3(){
        this.setPriceshow = false
      },
      cha4(){
        this.fileShow = false
      },
      cha5(){
        this.otherPayshow = false
      },
      setCop(val1,val2,val3,val4,val5){
        this.setPriceshow = true;
        this.serPrice = val1;
        this.agreementPrice = val2+val3;
        this.orderCode = val4;
        this.coupon_price = val5
        this.tanPay = this.serPrice - this.coupon_price
      },
      subniP(){
        if(String(this.coupon_price).substring(0,1) == '0'&&this.coupon_price !== 0){
          this.tip('error','请输入正确的优惠金额')
          return false;
        }else if(this.serPrice - this.coupon_price<this.agreementPrice){
          this.tip('error','请输入正确的优惠金额')
          return false;
        }else{
          this.tanPay = this.serPrice - this.coupon_price
            this.$http.post(this.GLOBAL.new_url+"/transactionv2/webapi/updateOrderCouponMoney",{
              couponMoney:this.coupon_price,
              orderCode:this.orderCode
          },{
                        headers:{
                            AuthToken:this.$cookie.getCookie('token'),
                            'X-Request-Origin':'xcx'
                        }
                                    }).then(res=>{
            // console.log(res)
            if(res.data.code == "200"){
              this.tip('success','设置成功')
              this.setPriceshow = false;
              this.getAlldata()
            }else{
              this.tip('error',res.data.msg)
            }
          })
        }
      },
      setFile(){
        this.fileShow = true;
      },
      choseCompany(){
        // console.log(this.dataList)
        for(var i =0;i<this.dataList.length;i++){
          if(this.authCode == this.dataList[i].authCode){
              this.userName = this.dataList[i].comLegalName;
              this.userPhone = this.dataList[i].authMobile;
              this.enterpriseName = this.dataList[i].comName;
              this.comRegisterAddr = this.dataList[i].comRegisterAddr;
          }
        }
      },
      submitFile(){
        let reg1 = /^0\d{2,3}-?\d{7,8}$/;
        let reg2=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(this.authCode ==''||this.userName == ''||this.userPhone==''||this.partyContactsInfo==''||this.partyMobilePhone==''){
          return false
        }else if(!reg1.test(this.userPhone)&&!reg2.test(this.userPhone)){
          this.tip('error','请填写正确的号码')
          return false
        }else if(!reg1.test(this.partyMobilePhone)&&!reg2.test(this.partyMobilePhone)){
          this.tip('error','请填写正确的号码')
          return false
        }else{

              var arr = [];
                for (var i = 0;i< this.paylist.length; i++) {
                    var obj = {}
                    obj.goodsName= this.paylist[i].goodsName;
                    obj.oneCate = this.paylist[i].oneCate;
                    obj.orderCode = this.paylist[i].orderCode;
                    obj.orderPrice = this.paylist[i].orderPrice;
                    obj.totalFee = this.paylist[i].totalFee;
                    obj.serviceTypeName = this.paylist[i].serviceTypeName;
                    obj.tradeCode = this.paylist[i].tradeCode;
                    obj.goodsType = this.paylist[i].goodsType;
                    arr.push(obj)
                }
                const loading = this.$loading({
              lock: true,
              text: '生成中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.3)'
            });
          this.$http.post(this.GLOBAL.new_url+"/protocol/webapi/generateDownloadProxyAgreement",{
              enterpriseName:this.enterpriseName,
              partyContactsInfo:this.partyContactsInfo,
              partyMobilePhone:this.partyMobilePhone,
              userCode:this.$cookie.getCookie('u_id'),
              tradeCode:this.paylist[0].tradeCode,
              comRegisterAddr:this.comRegisterAddr,
              userPhone:this.userPhone,
              userName:this.userName,
              list:arr
          },{
                        headers:{
                            AuthToken:this.$cookie.getCookie('token'),
                            'X-Request-Origin':'xcx'
                        }
                                    }).then(res=>{
            // console.log(res)
            if(res.data.code == "200"){
              this.fileShow = false;
              this.tip('success','生成成功')
              loading.close();
              this.document('委托代理合同',res.data.data,3)
              window.open(res.data.data,'_blank');
            }else{
              this.fileShow = false;
              loading.close();
              this.tip('error',res.data.msg)
            }
          }).catch((err) =>{
            this.fileShow = false;
            loading.close();
              console.log(err);
          })
        }
      },
      document(name,url,type){
        this.$http.post(this.GLOBAL.new_url+'/user/service/insertDocument',{
                docType:type,
                docName:name,
                docUrl:url,
                docNameInfo:'',
                orderCode:this.paylist[0].orderCode,
                userCode:this.$cookie.getCookie('u_id')
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
              
              if(res.data.code == "200"){
                console.log(123)
              }else{
                this.tip('error',res.data.msg)
              }
            });
      },
      count(){
        let reg1 = /^0\d{2,3}-?\d{7,8}$/;
        let reg2=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if(this.people == ""||this.moblie== ""||this.a==""||this.detailaddress ==""){
          return false;
        }else if(!reg1.test(this.moblie)&&!reg2.test(this.moblie)){
          this.tip('error','请填写正确的号码')
        }else{
          if(this.b == ''){
            var shi = this.shi;
          }else{
            var shi = this.b;
          }
          if(this.c == ''){
            var qu = this.qu;
          }else{
            var qu = this.c;
          }
          //  if(shi == '北京市'||shi == '上海市'||shi == '天津市'||shi == '重庆市'||shi == '香港'||shi == '澳门'||shi == '台湾'){
          //   shi = '';
          // }
          this.$http.post(this.GLOBAL.new_url+'/user/service/putAddrInfo',{
              userCode:this.$cookie.getCookie('u_id'),
              addrName:this.people,
              addrPhone:this.moblie,
              addrProvince:this.a,
              addrCity:shi,
              addrArea:qu,
              addrDetail:this.detailaddress,
            },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
              if(res.data.code == "200"){
                this.people = '';
                this.moblie = '';
                this.detailaddress = '';
                this.addressshow = false;
                this.tip('sucess','修改成功');
                this.getaddressdata();
              }
            })
        }
      },
      pick(){
        if(this.chose == ""){
          this.name = '';
          this.telephone = '';
        }else{
          // console.log(this.dataArr)
          for(var i = 0;i<this.dataArr.length;i++){
            if(this.chose == this.dataArr[i].addrCode){
              this.name = this.dataArr[i].addrName;
              this.telephone = this.dataArr[i].addrPhone;
              this.totalAddress = this.dataArr[i].addrProvince + this.dataArr[i].addrCity+ this.dataArr[i].addrArea+this.dataArr[i].addrDetail;
            }
          }
          this.comfireOrder()
        }
      },
      widnopen(){
        this.getlist();
        this.tan = true;
      },
      comfire(){
       if(this.pickcompany == '0'){
         this.tip('error','请选择发票抬头')
         return false
       }else if(this.chose == ""){
         this.tip('error','请选择邮寄地址')
         return false
       }else if(this.paytotal>100000){
         this.tip('error','开票金额不能大于十万')
         return false
       }else{
         var invoiceArr ={};
          for(var i =0;i<this.arr2.length;i++){
            if(this.pickcompany == this.arr2[i].invoiceCode){
              this.picktou = this.arr2[i].invoiceName;
              invoiceArr = this.arr2[i];
            }
          }
          this.countcompany = this.picktou;
          this.toushow = true;
          // console.log(invoiceArr,11111)
          this.disabled = true;

          if(this.kai == "one"){
            this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/modify/ordinaryInvoice',{
                email:invoiceArr.email,
                taxNum:invoiceArr.taxNum,
                companyTel:invoiceArr.companyTel,
                invoiceCode:this.pickcompany,
                companyAddr:invoiceArr.companyAddr,
                 invoiceName:invoiceArr. invoiceName,
                isDefault:invoiceArr.isDefault,
                orderCodes:this.$route.query.order
                },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                //   console.log(res)
                  if(res.data.code == "200"){
                    this.tip('success',res.data.msg);
                    this.tan = false;
                  }else{
                    this.tip('error',res.data.msg);
                  }
                })
          }else{
            this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/modify/specialInvoice',{
                email:invoiceArr.email,
                taxNum:invoiceArr.taxNum,
                companyTel:invoiceArr.companyTel,
                invoiceCode:this.pickcompany,
                addrCode:this.chose,
                companyAddr:invoiceArr.companyAddr,
                 invoiceName:invoiceArr. invoiceName,
                isDefault:invoiceArr.isDefault,
                orderCodes:this.$route.query.order,
                bank:invoiceArr.bank,
                bankAccount:invoiceArr.bankAccount
                },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                //   console.log(res)
                  if(res.data.code == "200"){
                    this.tip('success',res.data.msg);
                    this.tan = false;
                  }else{
                    this.tip('error',res.data.msg);
                  }
                })
          }

       }
     },
    makeActive(item){
      this.active = item;
      if(this.active == "company"){
        this.testshow = true
      }else{
        this.testshow = false
      }
    },
    Tonext(){

      if(this.chose == ""){
            this.tip('error','请选择邮寄地址')
            return false
          }else if(this.get == "2"&&this.toushow == false){
            this.tip('error','请选择开票信息')
            return false
          }
          // 银行转账
      if(this.active == "company"){
            if(this.countcompany == ''){
              this.tip('error','请填写付款公司名称');
              return false;
            }
            
        //     if(this.conponPrice !== 0){
        //       this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/updateOrderCouponMoneyByCouponForOfflinePay',{
        //   orderCodes:this.$route.query.order,
        //   userCouponCode:this.radio
        // }).then(res=>{
        //   if(res.data.code == "200"){
            
        //     }
        // })
        //     }
        this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/getPayCode',{
            orderCodeList:this.$route.query.order.split(","),
            goodsType:1
        },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
              if(res.data.code == "200"){
                  this.$http.post(this.GLOBAL.new_url+'/payOrder/offlinePay',{
                      payCode:res.data.data,
                      remittanceCompany:this.countcompany,
                      userCode:this.$cookie.getCookie('u_id')
                  },{
                              headers:{
                                  AuthToken:this.$cookie.getCookie('token'),
                                  'X-Request-Origin':'xcx'
                              }
                                          }).then(res=>{
                                            // console.log(res)
                        if(res.data.code == "200"){
                          if(this.paylist[0].goodsType == "1"){
                            if(this.paylist[0].goodsName == "商标注册"){
                                this.$router.push({
                                    path:'/registerData',
                                    query:{
                                        click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                    }
                                    });
                            }else if(this.paylist[0].serviceTypeName == "商标变更"){
                              this.$router.push({
                                  path:'/changeData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标续展"||this.paylist[0].serviceTypeName == "商标宽展"){
                              this.$router.push({
                                  path:'/extension',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标转让"){
                              this.$router.push({
                                  path:'/transferData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标许可备案"){
                              this.$router.push({
                                  path:'/filingData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "补发商标证书"){
                              this.$router.push({
                                  path:'/resendData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标驳回复审"){
                              this.$router.push({
                                  path:'/recheck',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标异议申请"){
                              this.$router.push({
                                  path:'/objectData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标异议答辩"){
                              this.$router.push({
                                  path:'/defence',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标撤三申请"){
                              this.$router.push({
                                  path:'/threeApply',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标撤三答辩"){
                              this.$router.push({
                                  path:'/threeAnswer',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                            }
                          }else{
                            this.$router.push({
                                  path:'/personal/order',
                                  query:{
                                    status: 'all'
                                  }
                                  });
                          }
                        }else{
                          this.tip('error',res.data.msg)
                        }
                      })

              }else{
                this.tip('error',res.data.msg)
              }
            })
      }
      // 支付宝
       if(this.active == "bao"){

         this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/getPayCode',{
            orderCodeList:this.$route.query.order.split(","),
            goodsType:1
          },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
              if(res.data.code == "200"){
                  var host = window.location.host;
                   var url='https://'+host+"/personal/order?status=all";
                  this.$http.post(this.GLOBAL.new_url+'/payOrder/aliPCPay',{
                      payCode:res.data.data,
                      returnUrl:url,
                  },{
                              headers:{
                                  AuthToken:this.$cookie.getCookie('token'),
                                  'X-Request-Origin':'xcx'
                              }
                                          }).then(res=>{
                    // console.log(res)
                    if(res.data.code == '200'){
                      let routeData = this.$router.resolve({ path: '/pay', query: { htmls: res.data.data }});
                        this.$router.push(routeData.href);
                    }
                  })
              }else{
                this.tip('error',res.data.msg)
              }
            })
      }
      // 微信
       if(this.active == "wei"){
          this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/getPayCode',{
            orderCodeList:this.$route.query.order.split(","),
            goodsType:1
          },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                  // console.log(res)
              if(res.data.code == "200"){
                this.pay_order_no = res.data.data;
                  this.$http.post(this.GLOBAL.new_url+'/payOrder/wxPay',{
                      payCode:res.data.data,
                      openId:'',
                      returnUrl:'',
                      tradeType:'NATIVE',
                      userCode:this.$cookie.getCookie('u_id')
                  },{
                              headers:{
                                  AuthToken:this.$cookie.getCookie('token'),
                                  'X-Request-Origin':'xcx'
                              }
                                          }).then(res=>{
                    // console.log(res)
                    if(res.data.code == '200'){
                      this.weiUrl = res.data.data.mwebUrl;
                      if(this.weiUrl!=''){
                        this.weishow = true;
                        this.creatQrCode()
                      }
                    }
                  })
              }else{
                this.tip('error',res.data.msg)
              }
            })

      }
       if(this.active == "yin"){
        console.log(4)
      }

    },
     creatQrCode() {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: this.weiUrl, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },
    weicha(){
      this.weishow = false;
      this.weiUrl = '';
      this.$refs.qrCodeUrl.innerHTML = '';
      clearInterval(this.timer);
      this.timer = null;
    },

    // 判断微信是否支付成功
    sucess(){
      if(this.pay_order_no != ''){
        this.$http.post(this.GLOBAL.new_url+'/payOrder/queryByPayOrderNo',{
              payOrderNo:this.pay_order_no
            },{
                              headers:{
                                  AuthToken:this.$cookie.getCookie('token'),
                                  'X-Request-Origin':'xcx'
                              }
                                          }).then(res=>{
              // console.log(res)
              if(res.data.data.payStatus == 2){
                this.weishow = false;
                if(this.paylist[0].goodsType == "1"){
                  if(this.paylist[0].goodsName == "商标注册"){
                                this.$router.push({
                                    path:'/registerData',
                                    query:{
                                        click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                    }
                                    });
                            }else if(this.paylist[0].serviceTypeName == "商标变更"){
                              this.$router.push({
                                  path:'/changeData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标续展"||this.paylist[0].serviceTypeName == "商标宽展"){
                              this.$router.push({
                                  path:'/extension',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标转让"){
                              this.$router.push({
                                  path:'/transferData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标许可备案"){
                              this.$router.push({
                                  path:'/filingData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "补发商标证书"){
                              this.$router.push({
                                  path:'/resendData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标驳回复审"){
                              this.$router.push({
                                  path:'/recheck',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标异议申请"){
                              this.$router.push({
                                  path:'/objectData',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标异议答辩"){
                              this.$router.push({
                                  path:'/defence',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标撤三申请"){
                              this.$router.push({
                                  path:'/threeApply',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }else if(this.paylist[0].serviceTypeName == "商标撤三答辩"){
                              this.$router.push({
                                  path:'/threeAnswer',
                                  query:{
                                      click_type:this.paylist[0].serviceTypeName,
                                        order_id:this.paylist[0].orderCode
                                  }
                                  });
                          }
                }else{
                  this.$router.push({
                      path:'/personal/order',
                      query:{
                        status: 'all'
                      }
                      });
                }
              }
            })
      }
    },
    getlist(){
        var a;
       if(this.kai == 'one'){
          a = "1"
       }else{
         a= "2"
       }
       this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/list',{
                    invoiceType:a,
                    pageNum:1,
                    pageSize:1000
                },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                  // console.log(res)
                  this.arr2 = res.data.data.list;
                })
     },
    addInvoice(){
      let routeData = this.$router.resolve({ path: '/personal/manageForm', query: { checkorder: 0 }});
      window.open(routeData.href, '_blank');
      this.tan = false;
    },
      getaddressdata(){
        this.$http.post(this.GLOBAL.new_url+'/user/service/getAddrList',{
          userCode:this.$cookie.getCookie('u_id')
        },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
          // console.log(res)
          if(res.data.code == "200"){
            this.dataArr = res.data.data;
            for(var i = 0;i<this.dataArr.length;i++){
              if(this.dataArr[i].isDefault == "1"){
                this.chose = this.dataArr[i].addrCode;
                this.totalAddress = this.dataArr[i].addrProvince + this.dataArr[i].addrCity+ this.dataArr[i].addrArea+this.dataArr[i].addrDetail;
                this.name = this.dataArr[i].addrName;
                this.telephone = this.dataArr[i].addrPhone;
              }
            }
            // this.comfireOrder()
          }
        })
      },
      comfireOrder(){
        this.$http.post(this.GLOBAL.new_url+'/orderv2/webapi/modifyOrderAddr',{
          addressName:this.name,
           addressSite:this.totalAddress,
           addressTel:this.telephone,
            orderCodes:this.$route.query.order
        },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
          // console.log(res)
          if(res.data.code == "200"){

          }
        })
      },
    otherPay(){
      if(this.chose == ''){
        this.tip('error','请先设置邮寄地址');
        return false;
      }else{
        this.otherPayshow = true;
      }

    },
    // pickConpon(){
    //   if(this.conpon.length == 0){
    //     this.tip('error','暂无优惠券');
    //   }else{
    //     this.conponShow = true;
    //   if(this.conpon1.length != 0){
    //       this.conpon1.map(x=>{
    //         if(x.optimal == "1"){
    //           this.conponPrice = x.discount_amount;
    //         }
    //       })
    //     }
    //   }
    // },
    // closeConpon(){
    //   this.conponShow = false;
    //   this.conponPrice = 0;
    // },
    // pickCouponcomfire(){
    //   if(this.conpon1.length !== 0){
    //     this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/updateOrderCouponMoneyByCoupon',{
    //       orderCodes:this.$route.query.order,
    //       userCouponCode:this.radio
    //     }).then(res=>{
    //       if(res.data.code == "200"){
    //         this.paytotal = 0
    //         for(var i =0;i<this.paylist.length;i++){
    //           this.paytotal += this.paylist[i].totalFee;
    //         }
    //         this.paytotal = this.paytotal - this.conponPrice;
    //         this.conponShow = false;
    //         // this.getCoupon()
    //         }
    //     })
    //   }else{
    //     this.conponShow = false;
    //   }
      
    // },
    // getCoupon(){
    //   this.$http.post(this.GLOBAL.new_url+'/coupon/queryCoupon',{
    //       orderCodes:this.$route.query.order
    //     }).then(res=>{
    //       // console.log(res)
    //         if(res.data.code == "200"){
    //           this.conpon = res.data.data;
    //           if(this.conpon.length!=0){
    //             this.conpon.map(x=>{
    //               x.validDateBegin = x.validDateBegin.slice(0, x.validDateBegin.indexOf(' '));
    //               x.validDateEnd = x.validDateEnd.slice(0, x.validDateEnd.indexOf(' '));
    //               x.useWay = JSON.parse(x.useWay)
    //               x.discount_amount = x.useWay.discount_amount;
    //               x.satisfy_amount = x.useWay.satisfy_amount;
    //               x.canUse = [];
    //               x.limitResults.map(m=>{
    //                 x.canUse.push(m.usable)
    //               })
    //               if(x.canUse.indexOf(false)==0){
    //                 this.conpon2.push(x)
    //               }
    //               if(x.canUse.indexOf(false)!==0){
    //                 this.conpon1.push(x)
    //               }
    //             })
    //             if(this.conpon1.length != 0){
    //               this.conpon1.map(x=>{
    //                 if(x.optimal == "1"){
    //                   this.radio = x.userCouponCode;
    //                 }
    //               })
    //             }
    //             // console.log(this.conpon1)
    //             // console.log(this.conpon2)
    //           }

    //         }else{
    //           this.tip('error',res.data.msg)
    //         }
    //     })
    // },
    // choseConpon(){
    //   console.log(this.radio)
    //   this.conpon1.map(x=>{
    //     if(this.radio == x.userCouponCode){
    //       this.conponPrice = x.discount_amount
    //     }
    //   })
    //   // console.log(this.conponPrice)
    // },
    getAlldata(){
      this.$http.post(this.GLOBAL.new_url+'/orderv2/webapi/queryOrderInfoAo',{
          orderCodeList:this.$route.query.order.split(",")
        },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
            // console.log(res)
            if(res.data.code == "200"){
              this.paylist = res.data.data;
              this.paytotal = 0;
              this.earning = 0;
              for(var i =0;i<this.paylist.length;i++){
                this.paytotal += this.paylist[i].totalFee;
                this.earning += this.paylist[i].earningsPrice;
              }
            }
        })
    },
    getperson(){
      this.$http.post(this.GLOBAL.new_url+'/user/service/getAdviserList',{
                                userCode:this.$cookie.getCookie('u_id'),
                                allAdvise:"1"
                              },{
                                            headers:{
                                                AuthToken:this.$cookie.getCookie('token'),
                                                'X-Request-Origin':'xcx'
                                            }
                                                        }).then(res=>{
                              // console.log(res)
                              if(res.data.code== "200"){
                                for(var i=0;i<res.data.data.length;i++){
                                if(this.$cookie.getCookie('consultant') == res.data.data[i].empCode){
                                    this.consultant = res.data.data[i]
                                    // console.log(this.consultant)
                                    this.partyContactsInfo = this.consultant.empName;
                                     this.partyMobilePhone = this.consultant.empMobile;
                                    }
                                }
                              }

                            })
    },
    getData1() {
      this.$http.post(this.GLOBAL.new_url+'/user/service/getPersonInfo',{
        userCode:this.$cookie.getCookie('u_id')
      },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
      // console.log(res)
      if(res.data.code== "200"){
        this.agentType = res.data.data.angentType;
      }
      })
    },
    // 取消开票
    cancelInvoice(){
        // console.log(this.$route.query.order)
      this.$http.post(this.GLOBAL.new_url+'/invoice/webapi/cancelInvoiceApply',{
            orderCodes:this.$route.query.order
        }).then(res=>{
          // console.log(res)
          if(res.data.code == "200"){
            console.log("取消成功")
          }
        })
    }
  },
  mounted(){
        this.getData1();
        this.getAlldata();
        this.getCityData();
        this.getaddressdata();
        this.getTest();
        this.getperson();
        this. cancelInvoice();
        // this.getCoupon()
        this.timer = setInterval(() =>{
            this.sucess()
        }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
}

</script>

<style scoped>
  .check-box-top{
    width: 100%;
    height: 188px;
    background-color: #f8f8f8;
  }
  .check-box-top-div{
    width: 1200px;
    height: 188px;
    margin: 0 auto;
    line-height: 188px;
  }
  .check-box-top-div>img{
    vertical-align: middle;
    margin-right: 25px;
    width: 150px;
    height: 80px;
  }
  .check-box-top-div>span{
    font-family: "PingFangSC-Semibold";
    font-size: 36px;
    font-weight: 600;
    color: #111111;
    vertical-align: middle;
  }
  .check-box-big{
    width: 1200px;
    margin: 40px auto 100px;
    /* border:1px solid red; */
  }
  .check-box-big-address{
    margin-bottom: 36px;
    height: 125px;
  }
  .address-div1{
    float: left;
    /* border:1px solid red; */
  }
  .address-div1>div:nth-of-type(1){
    font-size: 22px;
    font-weight: 600;
    font-family: "PingFangSC-Medium";
    margin-bottom: 16px;
    color: #333333;
  }
  .address-div1>div:nth-of-type(2){
    margin-bottom: 16px;
  }
  .address-div1>div:nth-of-type(2)>select{
    width: 480px;
    height: 40px;
    border-radius: 4px;
    outline: none;
    border: solid 1px #d6d6d6;
    padding-left: 14px;
    font-family: "PingFangSC-Regular";
    font-size: 18px;
    color: #333333;
    vertical-align: middle;
    cursor: pointer;
  }
  .address-div1>div:nth-of-type(3)>span{
    font-size: 16px;
    color: #EB5E00;
  }
  .address-div1>div:nth-of-type(3)>span:nth-of-type(1){
    color: #333333;
    margin: 0 226px 0 12px;
  }
  .address-div2{
    display: inline-block;
    width: 120px;
    height: 40px;
    border-radius: 4px;
    background: #EB5E00;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
    vertical-align: middle;
  }
  .order-infor{
    font-size: 22px;
    font-weight: 600;
    color: #333333;
    margin:40px 0 16px;
  }
  .check-box-big-order{
    padding: 16px 0;
  }
  .check-box-big-order-top{
    height: 53px;
    line-height: 53px;
    background: #F8F8F8;
  }
  .check-box-big-order-top>div{
    font-family: "PingFangSC-Medium";
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    float: left;
    width: 240px;
    text-align: center;
  }
  .check-box-big-order-top>div:last-child{
    padding-left: 30px;
    width: 210px;
    text-align: start;
  }
  .check-box-big-order-list{
    height: 60px;
    border: 1px solid #EEECEC;
    border-top: none;
  }
  .check-box-big-order-list>div{
    float: left;
    width: 239.6px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-family: "PingFangSC-Regular";
    font-size: 14px;
    font-weight: 400;
    color: #343434;
  }
  .check-box-big-order-list>div:last-child{
    text-align: start;
  }
  .check-box-big-order-list>div>span{
    display: inline-block;
    vertical-align: middle;
  }
  .check-box-big-order-list>div>span:nth-of-type(1){
    width: 95px;
    text-align: center;
  }
  .check-box-big-order-list>div>span:nth-of-type(2){
    width: 84px;
    height: 24px;
    background: #EB5E00;
    border-radius: 3px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: 10px;
  }
  #edit{
    color: #EB5E00;
    cursor: pointer;
  }
    .check-choseConpon{
      position: absolute;
      top: 50px;
      right: 45px;
    }
    .check-choseConpon>input{
      width: 16px;
      height: 16px;
    }
  .check-box-big-invoice{
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .invoice-is{
    font-size: 22px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 24px;
  }
  .invoice-radio{
    height: 25px;
  }
  .invoice-radio>div{
    float: left;
  }
  .invoice-radio>div:nth-of-type(1)>input{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 10px;
    cursor: pointer;
  }
  .invoice-radio>div:nth-of-type(1)>span{
    font-size: 18px;
    font-weight: 300;
    color: #333333;
    vertical-align: middle;
  }
  .invoice-radio>div:nth-of-type(1)>span:nth-of-type(1){
    margin-right: 80px;
  }
  .invoice-radio>div:nth-of-type(2){
    margin-left: 64px;
    font-size: 18px;
    color: #EB5E00;
    cursor: pointer;
  }
  .check-box-big-pay{
    overflow:hidden;
    margin-top: 40px;
  }
  .check-box-big-pay-type{
    font-family: "PingFangSC-Semibold";
    font-size: 22px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 20px;
  }
  .check-box-big-pay>div:nth-of-type(2){
      height: 80px;
  }
  .check-box-big-pay>div>div{
      float: left;
      width: 208px;
      margin-right: 24px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #F96009;
      opacity: 0.3;
  }
  .check-box-big-pay>div>div>div{
      float: left;
  }
  .check-box-big-pay>div>div>div:nth-of-type(1){
      width: 14px;
      height: 14px;
      border: 1px solid #F96009;
      border-radius: 50%;
      position: relative;
      margin: 32px 12px 32px 38px;
  }
  .check-box-big-pay>div>div>div:nth-of-type(1)>div{
      width: 8px;
      height: 8px;
      position: absolute;
      text-align: center;
      background: #F96009;
      border-radius: 50%;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
  }
  .check-box-big-pay>div>div>div:nth-of-type(2){
      margin-top: 23px;
  }
  .check-box-big-pay>div>div>div:nth-of-type(2)>img{
      height: 34px;
      width: 120px;
  }
  .company .div1,.bao .div2,.wei .div3{
      opacity: 1;
  }
  .test-div{
    height: 40px;
    margin: 24px 0 0;
  }
  .test-div>span{
    font-size: 18px;
    font-weight: 300;
    color: #333333;
    vertical-align: middle;
  }
  .test-div>input{
    width: 300px;
    height: 30px;
    border: solid 1px #d6d6d6;
    border-radius: 4px;
    outline: none;
    padding-left: 10px;
  }
  .check-contract{
    margin: 24px 0 0;
  }
  .check-contract>span{
    font-size: 14px;
    margin-right: 24px;
  }
  .check-contract>span:last-child{
    color: #EB5E00;
    cursor: pointer;
    text-decoration: underline;
  }
  .check-box-big-price{
    height: 37px;
    margin-bottom: 10px;
    margin-top: 24px;
    line-height: 37px;
    font-family: "PingFangSC-Semibold";
  }
  .another{
    margin-top: 0;
  }
  .check-box-big-price>div{
    float: left;
    font-size: 18px;
    color: #333333;
    margin-right: 15px;
  }
  .check-box-big-price>div:nth-of-type(2)>span:nth-of-type(1){
     color: #EB5E00;
  }
  #money{
    color: #EB5E00;
  }
  .check-box-big-commit{
    height: 54px;
    line-height: 54px;
    margin-top: 40px;
  }
  .check-box-big-commit>div{
    font-family: "PingFangSC-Regular";
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    line-height: 54px;
    float: left;
    width: 214px;
    height: 54px;
    border-radius: 3px;
  }
 .check-box-big-commit>div:nth-of-type(1){
    background: #F79002;
 }
 .check-box-big-commit>div:nth-of-type(2){
    background: #EB5E00;
    margin-left: 32px;
 }
 .add-address,.check-otherPay{
   background: rgba(0,0,0, 0.4);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
 }
 .address-form{
   width: 582px;
   height: 434px;
   background: #ffffff;
   position: absolute;
   top:50%;
   left: 50%;
   z-index: 30;
   transform: translate(-50%,-50%);
 }
 .address-form>img{
   width: 24px;
   height: 24px;
   position: absolute;
   right:20px;
   top:20px;
   cursor: pointer;
 }
 .form-div{
   position: absolute;
   top:81px;
   left: 96px;
 }
 .form-div>div{
   margin-bottom: 30px;
 }
 .form-div>div>span{
   font-size: 14px;
   margin-right: 16px;
   color: #333333;
 }
 .form-div>div>input{
    width: 290px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d6d6d6;
    outline: none;
    padding-left: 10px
 }
 .recevie-people>span,.address-div>span{
   margin-left: 14px;
 }
 .select1{
   width: 96px;
  height: 32px;
  border-radius: 4px;
  margin-right: 2px;
 }
 .count{
   width: 108px;
  height: 36px;
  border-radius: 4px;
  background-color:#EB5E00;
  margin: 0 auto;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
 }
  .check-box-tan,.cont-box-tan,.check-pickCoupon{
    background: rgba(0,0,0, 0.4);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .check-box-tan-div{
    width: 687px;
    height: 361px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%,-50%);
  }
  .check-box-tan-div>span{
    font-family: "PingFangSC-Regular";
    font-size: 16px;
    color: #333333;
    position: absolute;
    top: 30px;
    left: 316px;
  }
  .check-box-tan-div>img{
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  .check-box-tan-type{
    position: absolute;
    height: 28px;
    top: 103px;
    left: 62px;
  }
  .check-box-tan-type>span,.check-box-tan-add>span{
    font-family: "PingFangSC-Light";
    /* font-size: 20px; */
    font-weight: 300;
    vertical-align: middle;
    color: #333333;
  }
  .check-box-tan-type-span1{
    margin-right: 30px;
  }
  .check-box-tan-type>input{
    width: 20px;
    height: 20px;
    margin-right: 12px;
    vertical-align: middle;
    cursor: pointer;
  }
  .check-box-tan-type-span2{
    margin-right: 38px;
  }
  .check-box-tan-add{
     position: absolute;
     width: 563px;
     height: 40px;
     line-height: 40px;
     top: 153px;
     left: 62px;
  }
  .check-box-tan-add>span{
    margin-right: 30px;
  }
  .check-box-tan-add>div{
    float: right;
    width: 110px;
    height: 36px;
    border-radius: 8px;
    border-style: solid;
    margin-right: 10px;
    border-width: 2px;
    border-image-source: linear-gradient(to top, #d6d6d6, #d6d6d6);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #ffffff, #e3e3e3), linear-gradient(to top, #d6d6d6, #d6d6d6);
    background-origin: border-box;
    background-clip: content-box, border-box;
    text-align: center;
    line-height: 36px;
    font-family: "PingFangSC-Regular";
    font-size: 16px;
    color: #EB5E00;
    cursor: pointer;
  }
  .check-box-tan-add>select{
    width: 229px;
    height: 36px;
    border-radius: 4px;
    padding-left:15px;
    outline: none;
    border: solid 1px #d6d6d6;
  }
  .check-box-tan-comfire{
    position: absolute;
    width: 563px;
    height: 40px;
    line-height: 40px;
    bottom: 62px;
    left: 300px;
    width: 110px;
    height: 45px;
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to top, #ba6100, #dc773b);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #f79000, #EB5E00), linear-gradient(to top, #ba6100, #dc773b);
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-family: "PingFangSC-Regular";
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
.check-chose{
  margin: 10px 0 24px;
  font-size: 18px;
  color: #333333;
}
.check-chose2{
  margin: 10px 0 24px;
  font-size: 18px;
  color: red;
}
.check-coupon{
  height: 32px;
  margin: 0 0 23px;
}
.check-coupon>div{
  float: left;
}
.check-coupon>div:nth-of-type(1){
  font-size: 22px;
    font-weight: 600;
    color: #333333
}
.check-coupon>div:nth-of-type(2){
  width: 98px;
  height: 32px;
  background: #F96009;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  line-height: 32px;
  color: #FFFFFF;
}
.check-coupon-price{
  margin-bottom: 40px;
  color: #939393;
  font-size: 16px;
  font-weight: 400;
}
.check-coupon-price>span:nth-of-type(2){
  color: #EB5E00;
}
.weixin,.check-edit-discont{
     background: rgba(0,0,0, 0.4);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.weixin>img{
    width: 25px;
    height: 25px;
    position: absolute;
    top:35.5%;
    left: 55.5%;
    z-index: 30;
    cursor: pointer;
}
.weixin>div{
    width: 200px;
    height: 200px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%,-50%);
}
.weixin>div>img{
  width: 190px;
        height: 190px;
        background-color: #fff;
        padding: 5px;
        box-sizing: border-box;
}
.cont-box-tan-div{
    width: 600px;
    height: 484px;
    background-color: #ffffff;
    position: absolute;
    top:50%;
    left: 50%;
    z-index: 30;
    transform: translate(-50%,-50%);
}
.cont-box-tan-div>span{
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    position: absolute;
    top: 30px;
    left: 236px;
}
.cont-box-tan-div>img,.check-edit-div>img,.otherPay-div>img{
  position: absolute;
  top:12px;
  right: 12px;
  width: 21px;
  height: 21px;
  cursor: pointer;
}
.cont-box-tan-infor{
   position: absolute;
  top:82px;
  left: 120px;
}
.cont-box-tan-infor>span{
  font-size: 14px;
}
.cont-box-tan-infor>div{
  margin-top: 24px;
}
.cont-box-tan-infor>div>span,.check-edit-set>div>span{
  display: inline-block;
  width: 72px;
  font-size: 14px;
  color: #323232;
}
.cont-box-tan-div-select{
  width: 300px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 3px;
  border-color:#D8D8D8;
}
.cont-box-tan-infor>div>input{
  width: 288px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 3px;
  padding-left: 12px;
  border: 1px solid #D8D8D8;
  outline: none;
}
.cont-box-tan-sub{
  width: 108px;
  height: 36px;
  background: #EB5E00;
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.check-edit-div{
  width: 500px;
  height: 320px;
  background: #FFFFFF;
  position: absolute;
  top:50%;
  left: 50%;
  z-index: 30;
  transform: translate(-50%,-50%);
}
.check-edit-div>span{
    font-size: 16px;
    font-weight: 400;
    color: #323232;
    position: absolute;
    top: 30px;
    left: 218px;
}
.check-edit-set{
  position: absolute;
  top:70px;
  left:138px;
}
.check-edit-set>div{
  margin-bottom: 24px;
}
.check-edit-set>div>input{
  width: 140px;
  height: 32px;
  outline: none;
  padding-left: 12px;
  border-radius: 3px;
  border: 1px solid #D8D8D8;
}
.check-edit-set>div>div{
  font-size: 14px;
  color: #323232;
}
.otherPay-div{
  width: 500px;
   height: 254px;
  background: #FFFFFF;
   position: absolute;
   top:50%;
   left: 50%;
   z-index: 30;
   transform: translate(-50%,-50%);
}
.otherPay-div1{
  position: absolute;
  top:36px;
  left: 218px;
  font-size: 16px;
}
.otherPay-div2{
  width: 420px;
  position: absolute;
  top: 75px;
  font-size: 14px;
  font-weight: 400;
  left: 40px;
 text-align: center;
}
.div-url{
  text-align: start;
  display: inline-block;
  width: 420px;
  height: 98px;
 display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
}
.tag-read{
  display: inline-block;
  width: 108px;
  height: 36px;
  background: #EB5E00;
  border-radius: 3px;
  border: 1px solid #EB5E00;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  cursor: pointer;
font-weight: 400;
color: #FFFFFF;
}
.check-pickCoupon-tan{
  width: 1015px;
  height: 700px;
  background: #FFFFFF;
  position: absolute;
  top:50%;
  left: 50%;
  z-index: 30;
  transform: translate(-50%,-50%);
}
.check-pickCoupon-tan>img{
  position: absolute;
  cursor: pointer;
  top:14px;
  right: 14px;
}
.check-pickCoupon-tan>span{
  position: absolute;
  top:22px;
  left: 459px;
  color: #EB5E00;
  font-size: 20px;
}
.check-pickCoupon-tan-bigDiv{
  position: absolute;
  top: 85px;
  left: 85px;
  width: 860px;
  height: 510px;
  /* border:1px solid red; */
  overflow-y: auto;
}
.check-pickCoupon-tan-bigDiv::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.check-pickCoupon-tan-bigDiv::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.check-pickCoupon-tan-bigDiv::-webkit-scrollbar-track {
  opacity: 0;
}
.check-pickCoupon-comfire{
  width: 108px;
  height: 34px;
  background: #F96009;
  border-radius: 3px;
  position: absolute;
  right: 78px;
  bottom: 40px;
  cursor: pointer;
  line-height: 34px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
.pickCoupon-canUse{
  margin-bottom: 25px;
}
.pickCoupon-canUse-title{
  font-size: 18px;
  margin-bottom: 17px;
}
.pickCoupon-canUse-list-detail{
  width: 850px;
  height: 160px;
  background: url("../../../static/images/packet.png") 100% no-repeat;
  background-size: cover;
  margin-bottom: 16px;
  position: relative;
}
.pickCoupon-canUse-list>div>div{
  position: absolute;
}
.pickCoupon-canUse-div1{
  top:27px;
  left: 18px;
  color: #FF5E00;
}
.pickCoupon-canUse-div1>div:nth-of-type(1){
    font-size: 40px;
    margin-bottom: 10px;
}
.pickCoupon-canUse-div1>div:nth-of-type(2){
    font-size: 18px;
}
.pickCoupon-canUse-div1>div>span{
    font-size: 18px;
    margin-right: 5px;
}
.pickCoupon-canUse-div2{
        top:17px;
        left: 162px;
    }
    .pickCoupon-canUse-div2>div:nth-of-type(1){
        font-size: 20px;
        margin-bottom: 70px;
    }
    .pickCoupon-canUse-div2>div:nth-of-type(2){
        color: #8B8B8B;
        font-size: 14px;
    }
    .pickCoupon-canUse-div3{
      bottom: 28px;
      right:52px;
    }
    .noFordid{
      background: url("../../../static/images/packet4.png") 100% no-repeat;
      background-size: cover;
      margin-bottom: 32px;
    }
    .noUse{
      color: #666666;
    }
    .pickCoupon-noUse-div3{
      top:165px;
      left: 10px;
      color: #EB5E00;
      font-size: 14px;
    }
</style>
<style >
   .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #EB5E00;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #EB5E00;
    border-color: #EB5E00;
  }
  .el-radio__input.is-checked .el-radio__inner{
    background-color: #EB5E00;
    border-color: #EB5E00;
  }
</style>
